

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function PRPvampirefaq({setIsModalOpen}) {
    return (
        <>
        <Separator />
      
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-cente pb-5">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about <span>PRP Facial</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">


              <Col

              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does Facial hurt?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The treatment is minimally invasive and generally painless. The needles used are among the
                        thinnest, making the procedure virtually painless. Local anaesthetic cream is also available if
                        required.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How many PRP sessions London are required?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        3-4 sessions are ideally needed depending on the individual, followed by maintenance sessions if
                        desired/necessary. We will work together with you to create a treatment plan tailored to you, in
                        order to achieve optimal results.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What happens on the day?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We start the appointment with a thorough consultation to understand your requirements and
                        suitability. Once we have a full understanding and you’re happy to proceed, we start the
                        treatment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


                <br />


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long is recovery after Facial?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No recovery time is needed, you can get straight back to your daily activities right after the
                        treatment. It is advised to let the face heal for 24 hours by making sure you do not apply any
                        pressure directly to the face, following each session for 24 hours. Please also avoid makeup,
                        dirt or sweat in the area to prevent any infection till the face is fully healed. Further post
                        treatment advise will be explained during the consultation provided.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the side effects of Facials?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As the treatment uses only natural and safe ingredients of one’s own blood, there is no foreign
                        substance and thus having an allergic reaction or side effects to the substance is very
                        unlikely. <br /> <br />

                        Like with every injectable treatment, there may be some redness, minor bruising, swelling and a
                        slight discomfort at the injected areas. This is nothing to be concerned about and will subside
                        within 24-48 hours following the treatment

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is Facials safe? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, PRP treatments are safe. It is a 100% natural procedure using your own blood, so there is
                        no risk of an allergic reaction. PRP treatments, for many years have been and are currently in
                        use for medical purposes, such as healing of injured muscles and joints, tendons and ligaments.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is PRP Facial London?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Platelet-rich plasma (PRP) uses injections of a concentration of a patient's own platelets to
                        stimulate collagen production, improve fine lines and wrinkles as well as improving
                        hyperpigmentation. PRP injections use each individual patient's own healing system to improve
                        skin quality.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is PRP good for your face?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        PRP improves overall skin texture, decreases the appearance of fine lines and wrinkles around
                        the mouth and eyes, and plumps hollowing areas such as tear trough grooves, cheeks, and temples.
                        PRP can also help smooth both acne and surgical scars.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the side effects of PRP on the face?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Slight swelling and redness are the most common side effects. They typically appear immediately
                        after the procedure and clear within four to six days. You'll also want to avoid sun exposure
                        and harsh skin treatments during this time. It's important that you don't rub or pick at your
                        face.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How often should you have the PRP facial?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The first 3 sessions we advise leaving 4 weeks apart.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How soon will I see results from PRP Facial?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For PRP treatments of the face, you can expect to see some results starting at 2 or 3 weeks.
                        These results will be a brighter complexion, a healthy glow to the skin, and even a more plump
                        skin texture.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />

                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What not to do after a PRP facial?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For a minimum of 5 hours after the PRP has been applied to your skin, do NOT: wash your skin,
                        expose the treated area/s to direct high heat, or engage in activities that will get you wet or
                        cause you to sweat (e.g. blow dryer, sun exposure, sauna, steam room, Jacuzzi, very hot shower,
                        hot yoga, strenuous exercise, etc.)
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />


                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can PRP reduce eye bags?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        PRP Face is an effective treatment for the under-eye area as it increases blood vessel growth
                        and strengths collagen promoting a refreshed and youthful appearance. New blood vessels in the
                        under-eye area allow nutrients and oxygen into the skin, resulting in a decrease of puffiness
                        and loose under-eye skin is tightened.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion> <br />


                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why choose Dermamina?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We are renowned for providing natural looking yet life changing results and being completely
                        honest with our patients. If we feel a treatment is not suitable for you, we will not recommend
                        for you to go ahead with it.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion>

                <br />
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What if I change my mind after the consultation?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In the case of changing your mind after the consultation, you will be charged £20 for the
                        consultation and the remaining will be refunded or can be used towards another treatment.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


              </Col>


            </Row>


            <br /> <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>

          <SeparatorEnd />
        </>


    );
}







