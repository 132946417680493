import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/vampire/vamcontent";
import Package from "../sections/vampire/vampackage.js";
import Works from "../sections/vampire/vamwork";
import Gallery from "../sections/vampire/vamgallery";
import Faq from "../sections/vampire/vamfaq";
import Recovery from "../sections/vampire/vamrecovery";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/vampire-facial.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const VampirePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
            <Head title="Face Lift London" image="/images/dermamina-home.jpg"  description="Experts in non surgical nose job Rhinoplasty and skincare treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Face </span> <span style="color: #00aec7;">Lift</span>'
    secondText="A powerful effective natural treatment"
    videoAlt="Face Lift"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="3 - 6 treatments"
  bestalt="best Face Lift"
  resultstext="Long Lasting"
  resultsalt="Face Lift results"
  costdata="From £280"
  costalt="Face Lift Cost"
  timedata="30 Minutes"
  timealt="Face Lift Duration Time"
  workdata="Immediately"
  workalt="Face Lift downtime work"
  paindata="Medium"
  painalt="Face Lift Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Gallery />
<Award />
<Content setIsModalOpen={setIsModalOpen}/>
<Package />
<Testimonial />
<Works setIsModalOpen={setIsModalOpen}/>
<Faq setIsModalOpen={setIsModalOpen}/>
<Clinic /> 
<Saveface />
<Trend />
      </PageWrapper>
     
    </>
  );
};
export default VampirePage;
